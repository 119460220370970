.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer{
    padding-top: 7vh;
    width: 100%;
}
.mainContent{
    width: 100%;
    display: flex;

}

.rightContentWrapper{
    width: 76%;
    padding: 20px 2% 20px 0%;
    height: 87vh;
}




.noSubscribed{
    background-color: white;
    padding: 40px 2%;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    text-align: center;
}
.subscriptionWrapper{
    padding: 0%;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;

}
.missingOutTxt{
    font-weight: 500;
    color: #202020;
}
.betterWayTxt{
    padding: 20px 0px;
    font-weight: 600;
    color: #202020;
}

.explorePlansWrapper{
    display: flex;
    justify-content: center;
    padding: 20px 0px 10px;
}
.explorePlansWrapper a{
    padding: 12px 20px;
    background-color: #da1884;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    color: white;
    font-weight: 500;
    font-size: 0.8rem;
}

.eachPlanWrapper{
    background-color: #B8EDFF;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    padding: 12px;
}
.selectedPlan{
    font-weight: 500;
    background-color: #86DEFF;
    padding: 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    color: rgb(5, 5, 5);
}
.subscriptionList{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1vh 2px;
}
.subscriptionsCount{
    text-align: center;
    font-weight: 500;
    font-size: 0.7rem;
    color: #5f5f5f;
}
.tabWrapper{
    display: flex;
    height: 4vh;
    gap: 10px;
}
.eachTabWrapper{
    padding: 0px 30px;
    font-weight: 500;
    border-bottom: 2px solid transparent;
    color: #424242;
    cursor: pointer;
}
.eachTabWrapperActive{
    padding: 0px 30px;
    font-weight: 500;
    border-bottom: 2px solid #da1884;
}

.titleStatusWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.activeTag{
    font-size: 0.7rem;
    background-color: green;
    color: white;
    padding: 4px 16px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    font-weight: 600;
}
.expiredTag{
    font-size: 0.7rem;
    background-color: #FF0C00;
    color: white;
    padding: 4px 16px;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
    font-weight: 600;
}
.tagsWrapper{
    display: flex;
    padding: 10px 0px 5px;
}
.subscriptionTerms{
    font-size: 0.8rem;
    font-weight: 600;
    color: rgb(5, 5, 5);
}
.thatsAll{
    text-align: center;
    padding: 30svh 0px;
    color: #424242;
    font-size: 0.8rem;
    font-weight: 500;
}

@media only screen and (max-width: 600px) {
    .mainWrapper{
        height: 100svh;
    }
    .mainContainer{
        height: 93svh;
        padding-top: 7svh;
    }
    .rightContentWrapper{
        width: 100%;
        padding: 2svh 2%;
        height: 89svh;
    }
    .tabWrapper{
        height: 3svh;
        gap: 0px;
        display: flex;
        border-bottom: 1px solid #ffffff;
    }
    .eachTabWrapper{
        font-size: 0.8rem;
        
    }
    .eachTabWrapperActive{
        font-size: 0.8rem;
        
    }
    .subscriptionList{
        padding: 1svh 2px;
        height: 86svh;
        overflow-y: scroll;
    }

}