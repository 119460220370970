.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer{
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0px 5%;
}

.formWrapper{
    background-color: white;
    height: 70vh;
    width: 35%;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;

    overflow: hidden;
}

.forgotPasswordHeader{
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #dfdfdf;

    background: #dfdfdf54;
    font-weight: 600;
}




.forgotPasswordBody{
    width: 100%;
    height: 63vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.phoneNumberWrapper{
    width: 90%;
    padding: 20px 5%;
}

.phoneNumberWrapper input{
    width: calc(100% - 22px);
    padding: 14px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
.phoneNumberWrapper input:focus{
    outline-color: #da1884;
    pointer-events: none;
    cursor: text;
}
.resendOtpTxt{
    font-size: 0.7rem;
    text-align: right;
}
.otpWrapper{
    width: 90%;
    padding: 0px 5% 30px;
}


.otpBtnWrapper{
    display: flex;
    width: 90%;
}
.otpBtnWrapper button{
    width: 100%;
    padding: 14px;
    width: 100%;
    background-color: #da1884;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    border: 1px solid #da1884;
    color: white;
    font-weight: 600;

    cursor: pointer;
}
.otpBtnWrapper .sendOtpActiveBtn{
    width: 100%;
    padding: 14px;
    width: 100%;
    background-color: #da1884;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    border: 1px solid #da1884;
    color: white;
    font-weight: 600;

    cursor: pointer;
}
.otpBtnWrapper .sendOtpInactiveBtn{
    width: 100%;
    padding: 14px;
    width: 100%;
    background-color: #e28aba;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    
    border: 1px solid #da188480;
    color: white;
    font-weight: 600;
    
    cursor: pointer;
}
.submitBtnWrapper{
    display: flex;
    width: 90%;
    flex-direction: column;
    text-align: center;
}
.submitOtpBtn{
    width: 100%;
    padding: 14px;
    width: 100%;
    background-color: #da1884;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    border: 1px solid #da1884;
    color: white;
    font-weight: 600;

    cursor: pointer;
}

.resendSectionWrapper{
    display: flex;
    font-size: 0.8rem;
    justify-content: space-between;
    padding: 5px 8px 12px;
    font-weight: 500;
    color: #828282;
    width: calc(100% - 16px);
    font-size: 0.7rem;
    gap: 10px;
}

.hasSentWrapper{
    text-align: center;
    padding: 0px 20px 30px;
    font-weight: 500;
    font-size: 0.8rem;
}
.cancelBtn{
    margin: 20px 0px;
    font-weight: 500;
    font-size: 0.8rem;
    color: #828282;
}
@media only screen and (max-width: 600px) {
    .mainWrapper{
        height: 100svh;
    }
    .mainContainer{
        height: 100svh;
    }
    .formWrapper{
        width: 100%;
        height: 70svh;
    }
    .forgotPasswordHeader{
        height: 7svh;
    }
    .forgotPasswordBody{
        height: 63vh;
    }
    .phoneNumberWrapper input::placeholder{
        font-size: 0.7rem;
    }


}
