.mainWrapper {
    top: 0px;
    left: 0px;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #000000af;
    z-index: 1000;
}

.mainContainer {
    position: relative;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainContent {
    height: 80vh;
    width: 70%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
}

.mainHeaderWrapper{
    height: 10vh;
    border-bottom: 1px solid #dfdfdf;
    padding: 0px 10px;
    background-color: #f6f6f6;
}
.locationTitle{
    text-align: center;
    font-size: 0.8rem;
    padding: 4px 0px;
}
.mainBodyWrapper{
    height: 60vh;
}
.listContainer{
    height: 60vh;
}
.listSearchContainer{
    height: 7svh;
    border-bottom: 1px solid #dfdfdf;
}
.listSearchContainer input{
    width: calc(100% - 22px);
    padding: 0px 10px;
    border: 1px solid transparent;
    height: 7svh;
    background-color: #dfdfdf25;
}
.listSearchContainer input:focus{
    outline: none;
}
.listItemContainer{
    height: 50vh;
    overflow-y: scroll;
}
.eachStoreWrapper{
    padding: 10px 5%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
}
.storeDetailsWrapper{
    padding: 0px 0px 0px 10px;
    display: flex;
}
.eachCityWrapper{
    padding: 9px 5%;
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
}
.checkboxIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cityDetailWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}
.cityDetailWrapperDull{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    color: #696969;
}
.cityIcon{
    display: flex;
    align-items: center;
    padding: 0px 10px;
    font-weight: 600;
    font-size: 0.8rem;
    width: calc(90% - 20px);
}
.cityDetailWrapperDull .cityIcon{
    font-weight: 500;

}
.hasIncluded{
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainFooterWrapper{
    height: 10vh;
    border-top: 1px solid #dfdfdf;
    background-color: white;
    position: relative;
}
.optionsWrapper{
    display: flex;
    width: 94%;
    justify-content: space-between;
    padding: 6px 2%;
    background-color: #f5cde373;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.eachOptionsWrapper{
    width: calc(50% - 2px);
    background-color: transparent;
    border: 1px solid transparent;
    color: #da1884;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 8px 0px;
    text-align: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.eachOptionsWrapperActive{
    background-color: #f8bfde;
    width: calc(50% - 2px);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border: 1px solid #da1884;
    color: #da1884;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 8px 0px;
    text-align: center;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.mainFooterWrapper{
    display: flex;
    justify-content: space-around;
    align-items: center;

}
.closeBtn{
    width: 200px;
    padding: 12px 0px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: #e4e4e4;
    font-weight: 600;
}
.updateBtn{
    width: 200px;
    padding: 12px 0px;
    border: 1px solid #da1884;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: #da1884;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}


@media only screen and (max-width: 600px) {
    .mainWrapper {
        height: 100svh;
    }

    .mainContainer {
        height: 100svh;
    }

    .mainContent {
        width: 100%;
        height: 100svh;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }
    .listContainer{
        height: 80svh;
    }
    .listSearchContainer{
        height: 7svh;
        border-bottom: 1px solid #dfdfdf;
    }
    .listSearchContainer input{
        width: calc(100% - 22px);
        padding: 0px 10px;
        border: 1px solid transparent;
        height: 7svh;
        background-color: #dfdfdf25;
    }
    .listSearchContainer input:focus{
        outline: none;
    }
    .listItemContainer{
        height: 73svh;
        overflow-y: scroll;
    }
    .eachStoreWrapper{
        padding: 10px 5%;
        border-bottom: 1px solid #dfdfdf;
        display: flex;
    }
    .storeDetailsWrapper{
        padding: 0px 0px 0px 10px;
    }
    .storeName{
        font-size: 0.8rem;
        font-weight: 500;
    }
    .storeAddress{
        font-size: 0.6rem;
        font-weight: 500;
        color: #000000af;
    }

    .mainHeaderWrapper{
        height: 11svh;
    }
    .mainBodyWrapper{
        height: 79svh;
    }
    .mainFooterWrapper{
        height: 10svh;
    }
    .closeBtn{
        width: 45%;
    }
    .updateBtn{
        width: 45%;
    }
    
}