.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer{
    padding-top: 7vh;
    width: 100%;
}
.mainContent{
    width: 100%;
    display: flex;

}

.rightContentWrapper{
    width: 76%;
    padding: 20px 2% 20px 0%;
}

.changePasswordWrapper{
    width: 94%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    padding: 0px 2%;
}
.changePasswordContainer{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 40%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 50px 5% 65px;
    display: flex;
    gap: 15px;
    flex-direction: column;
}
.eachPasswordFieldWrapper{
    width: 100%;
}
.epfwTitleWrapper{
    display: flex;
    justify-content: space-between;
}
.epfwTitle{
    font-weight: 500;
    font-size: 0.8rem;
    color: #333333d7;
    padding: 0px 10px 0px 0px;
}
.reason{
    color: red;
    font-size: 0.7rem;
    padding: 0px 0px 10px;
}
.epfwInput input{
    width: calc(100% - 22px);
    padding: 12px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #dfdfdf40;
}
.actionWrapper{
    display: flex;
    justify-content: center;
    width: 100%;
}
.updatePasswordActive{
    width: 100%;
    background-color: #da1884;
    border: 1px solid #da1884;
    padding: 14px 0px;
    color: white;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 600;
}
.updatePasswordInActive{
    width: 100%;
    background-color: #da18832f;
    border: 1px solid #da1884;
    padding: 14px 0px;
    color: #da1884;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-weight: 600;
}
@media only screen and (max-width: 600px) {
    .mainWrapper{
        height: 100svh;
    }
    .mainContainer{
        height: 93svh;
        padding-top: 7svh;
    }
    .mainContent{
        height: 93svh;
        padding: 0px;
    }
    .rightContentWrapper{
        height: 93svh;
        width: 96%;
        padding: 0px 2% 0px 2%;
    }
    .changePasswordWrapper{
        height: 93svh;
    }
    .changePasswordContainer{
        width: 100%;
        padding: 30px 5% 45px;
    }
}