.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer{
    padding-top: 7vh;
    width: 100%;
}
.mainContent{
    width: 100%;
    display: flex;

}

.rightContentWrapper{
    width: 76%;
    padding: 20px 2% 20px 0%;
}

.contactWrapper{
    background-color: white;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    padding: 4%;
    width: 92%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.contactHeader h4{
    font-weight: 600;
    font-size: 1.4rem;
    padding: 2px 0px;
    margin: 2px 0px;
}
.eachSectionWrapper{
    padding: 18px 0px ;
}
.sectionCaption{
    font-weight: 500;
}
.sectionValue{
    color: #666666;
    font-weight: 500;
    padding: 5px 0px 0px 0px;
    font-size: 0.9rem;
}

.noteWrapper{
    border-top: 1px solid #dfdfdf;
    padding: 10px 0px 0px;
    font-weight: 500;
    font-size: 0.8rem;
    color: #7c7a7a;
}



@media only screen and (max-width: 600px) {
    .rightContentWrapper{
        width: 96%;
        padding: 20px 2% 20px 2%;
    }


}
