.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100svh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer {
    padding-top: 7svh;
    width: 100%;
}

.mainContent {
    width: 100%;
    display: flex;

}

.rightContentWrapper {
    width: 76%;
    padding: 20px 2% 20px 0%;
    overflow-y: scroll;
    height: 87svh;
}



.agreementMainWrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100svh;
    background-color: #000000d4;
}

.agreementMainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100svh;
}

.agreementMainContent {
    width: 80%;
    height: 90svh;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    display: flex;
    overflow: hidden;
}

.agreementLeftWrapper {
    width: 65%;
    height: 90svh;
    border-right: 1px solid #dfdfdf;
}

.agreementRightWrapper {
    width: 35%;
    height: 90svh;
    background-color: #dfdfdf40;
}

.agreementRightContainer {
    width: 100%;
    height: 90svh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.enterOtpWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.enterOtpWrapper input {
    height: 40px;
    width: 40px;
}
.mainBodyWrapperSigned{
    text-align: center;
}
.actionWrapperSigned{
    padding: 15px 0px;
}
.actionWrapperSigned a{
    background-color: #EFEFEF;
    padding: 12px 20px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

@media only screen and (max-width: 600px) {
    .agreementMainContent {
        width: 90%;
        height: 95svh;
        flex-direction: column;
        border-radius: 14px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
    }

 

    .agreementLeftWrapper {
        width: 100%;
        height: 95svh;
        border: none;
    }


}