.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer{
    padding-top: 7vh;
    width: 100%;
}
.mainContent{
    width: 100%;
    display: flex;

}

.rightContentWrapper{
    width: 76%;
    padding: 20px 2% 20px 0%;
}
.resumeTableWrapper{
    background-color: white;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    height: 86vh;
}

.filtersWrapper{
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1.5%;
}
.leftFiltersWrapper{
    width: 60%;
}
.leftFiltersWrapper input{
    width: calc(100% - 14px);
    padding: 9px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.leftFiltersWrapper input:focus{
    outline: none;
}
.headerWrapper{
    height: 6vh;
    background-color: #F6F9FA;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 500;
}

.tableBodyWrapper{
    height: 72vh;
    overflow-y: scroll;
}



.sn{
    width: 4%;
    text-align: center;
}
.candidateName{
    width: 16%;
}
.phoneNumber{
    width: 15%;
}
.profile{
    width: 20%;
}
.age{
    width: 16%;
}
.gender{
    width: 10%;
}
.action{
    width: 20%;
}




@media only screen and (max-width: 600px) {
    .rightContentWrapper{
        width: 100%;
    }


}
