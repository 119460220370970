.mainWrapper{
    background-color: #E7EEF7;
    padding: 2%;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
}

.planTagWrapper{
    display: flex;
    align-items: center;
    gap: 10px;
    text-align: center;
    justify-content: center;
    padding: 20px 0px 4px;
}
.brandLogo img{
    height: 12px;
}
.tagWrapper{
    padding: 5px 14px;
    background-color: #da1884;
    transform: skew(150deg);
    -webkit-transform: skew(150deg);
    -moz-transform: skew(150deg);
    -ms-transform: skew(150deg);
    -o-transform: skew(150deg);
    position: relative;
}
.tag{
    transform: skew(-150deg);
    -webkit-transform: skew(-150deg);
    -moz-transform: skew(-150deg);
    -ms-transform: skew(-150deg);
    -o-transform: skew(-150deg);
    font-size: 0.75rem;
    font-weight: 500;
    color: white;
}
.planTagLine{
    text-align: center;
    font-weight: 500;
    padding: 12px 0px;
    color: #7c7c7c;
}

.validTxt{
    text-align: center;
    font-weight: 500;
}

.planBenefitsWrapper{
    padding: 3%;
}
.planBenefitsContainer{
    border: 1px solid #C1D3EA;
    background-color: #F7F0E7;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}
.planBenefitTitle{
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.8px;
    padding: 10px 0px;
}
.eachPlanBenefitWrapper{
    width: 33%;
}

.epfTitle{
    font-weight: 500;
}
.epfCaption{
    font-size: 0.7rem;
    font-weight: 500;
    color: #7c7c7c;
    padding: 10px 0px;
}


.faqContainer{
    margin: 10px 0px;
    border: 1px solid #C1D3EA;
    background-color: #F7F0E7;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-direction: column;
}
.txtTitleWrapper{
    display: flex;
    align-items: center;
    gap: 10px;
}
.txtIcon{
    height: 40px;
    width: 40px;
    border: 1px solid #C1D3EA;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    -ms-border-radius: 40px;
    -o-border-radius: 40px;
    background-color: #C1D3EA;
}
.txtTitle{
    font-weight: 500;
}





@media only screen and (max-width: 600px) {
    .eachPlanBenefitWrapper{
        width: 100%;
    }
    .planBenefitsContainer{
        flex-wrap: wrap;
    }
}