.mainWrapper {
    width: 22%;
}

.mainContainer {
    width: 86%;
    padding: 0px 7%;
}

.eachSectionWrapper {
    width: 100%;
    padding: 0px 0px 20px 0px;
}

.sectionTitle {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    padding: 12px 0px;
}

.eachOptionWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 0px;
}

.optionIcon {
    display: flex;
    align-items: center;
}

.optionTitle {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 500;
}

.sectionOptionsWrapper {
    padding: 0px 10px;
}

.mainContainerMobile {
    display: none;
}


@media only screen and (max-width: 600px) {
    .mainWrapper{
        display: none;
    }
    .mainContainer {
        display: none;
    }

    

}


