.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer{
    padding-top: 7vh;
    width: 100%;
}
.mainContent{
    width: 100%;
    display: flex;

}

.rightContentWrapper{
    width: calc(76% - 1px);
    padding: 20px 2% 20px 1px;
    overflow-y: scroll;
    height: 80vh;
}


.notSubscribedForAnyPlanWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 80%;
}
.notSubscribedForAnyPlanContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.nsfapTxt h2{
    font-weight: 500;
}
.titleWrapper{
    background-color: #FBF4FF;
    margin: 10px 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    padding: 8px 4%;
    text-align: center;
    font-weight: 500;
    font-size: 0.8rem;
}
.allAgreementsWrapper{
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.eachAgreementRow{
    display: flex;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-bottom: 1px solid #FBF4FF;
    padding: 12px 10px;
}
.agreementSearchFilterWrapper{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E5E3F2;
    padding: 10px 10px;
}
.searchWrapper input{
    padding: 12px 10px;
    border: 1px solid #dfdfdf;
    background-color: #FBF4FF;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}
.statusWrapper{
    background-color: #FBF4FF;
    overflow: hidden;
}
.statusWrapper select{
    padding: 12px 10px;
    background-color: #FBF4FF;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.selectedPlan{
    width: 40%;
}
.selectedPlanTxt{
    font-weight: 500;
}
.createdOn{
    font-size: 0.6rem;
    font-weight: 500;
}
.currentStatus{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.actionWrapper{
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.actionWrapper a{
    padding: 4px 8px;
    background-color: #dfdfdf60;
    font-size: 0.7rem;
    font-weight: 500;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #dfdfdf;
    text-align: center;

}
.notSigned{
    font-size: 0.7rem;
    background-color: #ffcb08;
    font-weight: 500;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    padding: 4px 14px;
}
.signed{
    font-size: 0.7rem;
    background-color: green;
    font-weight: 500;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
    padding: 4px 14px;
    color: white;
}
.noAgreementAvailable{
    text-align: center;
    padding: 80px 0px;
    font-weight: 500;
}

@media only screen and (max-width: 600px) {
    .rightContentWrapper{
        width: 94%;
        padding: 0px 3%;
    }
    .allAgreementsWrapper{
        width: 100%;
    }
    .statusWrapper select{
        width: 100%;
    }
    .notSigned{
        font-size: 0.5rem;
    }
    .signed{
        font-size: 0.5rem;
    }
}