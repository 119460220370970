.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer {
    height: 100vh;
    width: 100%;
}

.mainContent {
    display: flex;
}

.firstPaintWrapper {
    display: flex;
    height: 100vh;
    width: 100%;
    flex-direction: column;
}

.fpHeaderWrapper {
    height: 7vh;
    width: 100%;
}

.fpHeaderContainer {
    width: 100%;
    display: flex;
}

.fpHeaderLeft {
    width: 50%;
}

.fpHeaderRight {
    width: calc(50% - 20px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 7vh;
    padding: 0px 10px;
}

.changeLangWrapper {
    padding: 0px 10px;
    background-color: white;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    -ms-border-radius: 28px;
    -o-border-radius: 28px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.changeLangWrapper:hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.changeLanguageOption {
    padding: 5px 12px;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    -ms-border-radius: 28px;
    -o-border-radius: 28px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    color: #828282;
}
.changeLanguageOption{
    background-color: transparent;
}
.changeLanguageOption:focus {
    outline: none;
}

.fpBodyWrapper {
    height: 83vh;
    width: 100%;
    display: flex;
}

.fpFooterWrapper {
    height: 10vh;
    width: 100%;
    /* background-color: #F5F5F7; */
    background-color: transparent;
}

.fpFooterContainer {
    padding: 0px 3%;
    display: flex;
    gap: 15px;
    align-items: center;
    height: 10vh;
    font-weight: 500;
    font-size: 0.7rem;
    color: #828282;
}

.fpFooterContainer a {
    color: #828282;
}

.leftWrapper {
    width: 60%;
    height: 83vh;
}

.stylelink {
    color: #da1884;
}

.stylelink h1 {
    padding: 0px;
    margin: 0px;
    font-weight: 600;
}

.numberOneHiringPlatform h2 {
    padding: 6px 0px 20px;
    margin: 0px;
}

.messageWrapper {
    display: flex;
    height: 86vh;
    width: 90%;
    padding: 0px 5%;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.uspWrapper {
    display: flex;
    gap: 20px;
}

.eachUspWrapper {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    background-color: white;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    padding: 0px 20px;
    color: #828282;
    font-weight: 500;
    font-size: 0.9rem;
}

.rightWrapper {
    width: 40%;
    height: 86vh;
}

.rightContainer {
    width: 100%;
    height: 86vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginWrapper {
    /* background-color: #FFAEBC; */
    background-color: #ffffff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 50vh;
    width: 70%;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 30px;
}

.loginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.fieldsWrapper {
    width: 100%;
}

.title {
    text-align: center;
    padding: 24px 0px;
    color: #1c1c1c90;
    font-weight: 500;
    font-size: 0.9rem;
}

.usernameWrapper {
    padding: 0px 0px 0px 0px;
    display: flex;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow: hidden;
    margin: 0px 0px 14px 0px;
    align-items: center;
    justify-content: space-between;
}
.usernameWrapper input {
    /* border: none; */
    padding: 0px 12px;
    width: calc(94% - 26px);
    height: 46px;
    border: none;
}
.passwordEye{
    text-align: center;
    width: 6%;
    cursor: pointer;
}

.usernameWrapper input:focus {
    outline: none;
}
.usernameWrapperValidated{
    display: flex;
    justify-content: space-between;
    height: 50px;
    background-color: #dfdfdf45;
    align-items: center;
    padding: 0px 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin: 0px 0px 10px 0px;
    font-weight: 500;
}
.username{
    color: #828282;
}
.usernameWrapperValidated button{
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-weight: 600;
    color: #828282;
    font-size: 0.7rem;
}

.resendSectionWrapper{
    display: flex;
    font-size: 0.8rem;
    justify-content: space-between;
    padding: 5px 8px 12px;
    font-weight: 500;
    color: #828282;
}


.sendOtpWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px 20px;
}
.sendOtpTxt{
    text-align: center;
    display: flex;
    align-items: center;
}
.horiLine{
    width: 40px;
    height: 2px;
    background-color: #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: center;
}
.enterPasswordToggle{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 0px 10px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    font-weight: 500;
    color: #828282;
    font-size: 0.7rem;
}
.sendOtpBtn{
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    padding: 0px 10px;
    background-color: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    font-weight: 500;
    color: #828282;
}

.nextBtnWrapper button {
    padding: 0px 12px;
    height: 46px;
    width: calc(100%);
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    cursor: pointer;
    background-color: #da1884;
    color: white;
    font-weight: 600;
}

.nextBtnWrapper button:focus {
    outline: none;
}

.forgotPasswordWrapper {
    text-align: center;
    font-weight: 500;
    padding: 60px 0px 20px;
}

.forgotPasswordWrapper a {
    color: #828282;
}







@media only screen and (max-width: 600px) {
    .mainWrapper{
        height: 100svh;
    }
    .mainContainer{
        height: 100svh;
    }
    .mainContent{
        height: 100svh;
    }
    .firstPaintWrapper{
        height: 100svh;
    }
    .fpBodyWrapper {
        flex-direction: column;
        gap: 30px;
    }
    .numberOneHiringPlatform h2 {
        padding: 6px 0px 20px;
        margin: 0px;
        font-size: 1rem;
    }
    .leftWrapper{
        width: 100%;
        height: auto;
    }
    .messageWrapper{
        height: auto;
    }
    .rightWrapper{
        width: 100%;
        height: auto;
    }
    .rightContainer{
        height: auto;
    }
    .loginWrapper{
        min-height: 40vh;
        max-height: 45vh;
    }
    .forgotPasswordWrapper{
        padding: 40px 0px 20px;
    }
    .uspWrapper{
        gap: 10px;
        flex-wrap: wrap;
    }
    .eachUspWrapper{
        font-size: 0.7rem;
        height: 20px;
        padding: 3px 10px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
}
    .usernameWrapper input::placeholder{
        font-size: 0.6rem;
    }

    .fpFooterContainer{
        flex-wrap: wrap;
        gap: 0px 15px;
    }

    .fpFooterWrapper{
        height: 6vh;
    }
    .fpFooterContainer{
        height: 6vh;
    }

}


@media only screen and (max-width: 350px) {
    .usernameWrapper input::placeholder{
        font-size: 0.5rem;
    }
}
