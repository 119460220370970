.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer{
    padding-top: 7vh;
    width: 100%;
}
.mainContent{
    width: 100%;
    display: flex;

}

.rightContentWrapper{
    width: 76%;
    padding: 20px 2% 20px 0%;
}
.resumeTableWrapper{
    background-color: white;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    height: 86vh;
    overflow: hidden;
}

.filtersWrapper{
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1.5%;
    width: 97%;
}
.leftFiltersWrapper{
    width: 60%;
}
.leftFiltersWrapper input{
    width: calc(100% - 14px);
    padding: 9px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.leftFiltersWrapper input:focus{
    outline: none;
}
.headerWrapper{
    height: 6vh;
    background-color: #F6F9FA;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0px 10px;
}

.tableBodyWrapper{
    height: 72vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 1%;
}



.sn{
    width: 4%;
    text-align: center;
}
.candidateName{
    width: 16%;
}
.phoneNumber{
    width: 15%;
}
.profile{
    width: 20%;
}
.age{
    width: 16%;
}
.gender{
    width: 10%;
}
.action{
    width: 20%;
}


.eachJobWrapper{
    display: flex;
    flex-direction: column;
    border: 1px solid #dfdfdf;
    padding: 10px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    background-color: #dfdfdf20;
    justify-content: space-between;
}
.eachJobUpperOne{
    display: flex;
    justify-content: space-between;
}
.jobTitle{
    padding: 4px 0px 2px;
    font-size: 1.2rem;
    font-weight: 500;
}
.jobProfile{
    font-size: 0.7rem;
    font-weight: 500;
}
.tagsWrapper{
    display: flex;
    gap: 10px;
    padding: 14px 0px 0px;
}
.salaryRangeWrapper{
    display: flex;
    border: 1px solid #FF8343;
    padding: 4px 6px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-size: 0.7rem;
    background-color: #FF8343;
    color: white;
    font-weight: 500;
}
.fnaWrapper{
    display: flex;
    border: 1px solid #6482AD;
    padding: 4px 6px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-size: 0.7rem;
    background-color: #6482AD;
    color: white;
    font-weight: 500;
}
.experienceWrapper{
    display: flex;
    border: 1px solid #B5CFB7;
    padding: 4px 6px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    font-size: 0.7rem;
    background-color: #B5CFB7;
    color: white;
    font-weight: 500;
}
.thatsAll{
    text-align: center;
    padding: 20vh 0px;
    font-size: 0.8rem;
}
.currentStatusTagRequestAccepted{
    display: flex;
    align-items: center;
    color: #E68369;
    font-weight: 500;
    font-size: 0.8rem;   
}
.currentStatusTagWorking{
    display: flex;
    align-items: center;
    color: #FFAF00;
    font-weight: 500;
    font-size: 0.8rem;   
}
.currentStatusTagHired{
    display: flex;
    align-items: center;
    color: green;
    font-weight: 600;
    font-size: 0.8rem;
}
.createdOn{
    font-size: 0.8rem;
    text-align: right;
    font-weight: 500;
}
.storeDetailsWrapper{
    padding: 10px 0px 5px;
}

.storeName{
    font-size: 0.9rem;
    font-weight: 500;
}
.address{
    font-size: 0.7rem;
    font-weight: 500;
    color: #5f5f5f;
}
.dropDownWrapper{
    width: 40%;
    display: flex;
    justify-content: flex-end;
}
.dropDownWrapper select{
    width: 80%;
    padding: 8px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 500;
}
.dropDownWrapper select:focus{
    outline: none;
}


@media only screen and (max-width: 600px) {
    .mainWrapper{
        height: 100svh;
    }
    .mainContainer{
        height: 93svh;
        padding-top: 7svh;
    }
    .mainContent{
        height: 93svh;
    }
    .rightContentWrapper{
        width: 96%;
        height: 89svh;
        padding: 2svh 2%;
    }
    .jobTitle{
        font-size: 0.8rem;
    }
    .currentStatusTagRequestAccepted{
        font-size: 0.7rem;
    }
    .currentStatusTagWorking{
        font-size: 0.7rem;
    }
    .currentStatusTagHired{
        font-size: 0.7rem;
    }
    .createdOn{
        font-size: 0.6rem;
        color: #5f5f5f;
        font-weight: 600;
        padding: 20px 0px 0px;
    }
}
