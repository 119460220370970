.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer{
    padding-top: 7vh;
    width: 100%;
}
.mainContent{
    width: 100%;
    display: flex;

}

.rightContentWrapper{
    width: 76%;
    padding: 2vh 2% 2vh 0%;
    height: 89vh;
}
.storesDataWrapper{
    height: 88vh;
}
.storesDataContainer{
    height: 88vh;
}
.toggleBtnWrapper{
    height: 3vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.toggleBtn{
    padding: 4px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 0.7rem;
    font-weight: 500;
    background-color: whitesmoke;
}
.toggleBtnActive{
    padding: 4px 8px;
    border: 1px solid #da1884;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 0.7rem;
    font-weight: 500;
    background-color: #da1884;
    color: white;
}
.searchBarWrapper{
    height: 7vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.searchBarWrapper input{
    width: calc(100% - 22px);
    height: calc(5vh - 2px);
    border: 1px solid #dfdfdf;
    padding: 0px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.sdcBodyWrapper{
    height: 79vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0px 2px;
    flex-wrap: wrap;

}
.eachStoreWrapper{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    background-color: white;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    padding: 2%;

    width: 44%;
    min-height: 100px;
}
.storeIdentifierWrapper{
    display: flex;
    justify-content: flex-start;
}
.storeIdentifier{
    font-weight: 600;
    border: 1px solid #dfdfdf;
    padding: 6px 8px;
    font-size: 0.7rem;
    letter-spacing: 1.1px;
    text-align: right;
    background-color: #dfdfdf45;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.storeName{
    font-weight: 500;
    padding: 8px 0px 4px;
    color: #303030;
}
.storeAddress{
    font-size: 0.7rem;
    font-weight: 500;
    color: #6e6e6e;
    padding: 0px 0px 14px;
}
.createdOn{
    font-size: 0.5rem;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 500;
}


@media only screen and (max-width: 600px) {
    .mainWrapper{
        height: 100svh;
    }
    .mainContainer{
        height: 93svh;
        padding-top: 7svh;
    }
    .mainContent{
        height: 93svh;
    }
    .rightContentWrapper{
        width: 96%;
        padding: 2svh 2% 2svh 2%;
        height: 89svh;
    }
    .storesDataWrapper{
        height: 89svh;
    }
    .storesDataContainer{
        height: 89svh;
    }
    .eachStoreWrapper{
        padding: 4%;
        width: 92%;
    }
    .toggleBtnWrapper{
        height: 3svh;
    }
    .searchBarWrapper{
        height: 7svh;
    }
    .searchBarWrapper input{
        font-size: 0.7rem;

    }
    .sdcBodyWrapper{
        height: 77svh;
        padding: 1svh 2px;
        overflow-y: scroll;
    }

}
