.mainWrapper{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000;
    background-color: #00000029;
}
.mainWrapper img{
    width: 80px;
    height: 80px;
}


@media only screen and (max-width: 600px) {
    .mainWrapper img{
        width: 60px;
        height: 60px;
    }


}