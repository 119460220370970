.mainWrapper {
    width: 100%;
    height: 90svh;
}

.mainHeader {
    height: calc(6vh - 1px);
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    background-color: #e5e5e5;
}
.planBenefitsWrapper{
    border: 1px solid #dfdfdf;
    margin-bottom: 10px;
}
.eachPlanBenefitRow{
    display: flex;
    justify-content: space-between;
}
.eachPlanBenefitRow:not(:last-child){
    border-bottom: 1px solid #dfdfdf;
}
.epbrTitle{
    border-right: 1px solid #dfdfdf;
    font-weight: 500;
    padding: 10px;
    width: 50%;
}
.epbrValue{
    padding: 10px;
}
.mainBody {
    height: 78vh;
    overflow-y: scroll;
}

.mainFooter {
    height: calc(6vh - 1px);
    border-top: 1px solid #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 0px 10px;
}
.mainFooter button{
    display: flex;
    align-items: center;
    justify-content: center;
}

.invoiceWrapper {
    padding: 0px 2%;
    text-align: justify;
    font-size: 12px;
}

.mainContractTitle {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    padding: 10px 0px;
}

.agreementDateWrapper {
    padding: 0px 0px;
}

.agreementDate {
    font-weight: 600;
}

.partiesWrapper {
    padding: 0px 0px;
}

.firmNameWrapper {
    font-weight: 600;
}

.secondPartyName {
    font-weight: 600;
}

.secondPartyAddress {
    font-weight: 600;
}

.textAlignCenter {
    text-align: center;
    font-weight: 600;
}

.subscriptionFeeTerms {
    font-weight: 600;
}

.hiringFeeTerms {
    font-weight: 600;
}

.makeItBold {
    font-weight: 600;
}


.signingWrapper {
    display: flex;
    justify-content: space-between;
    border: 1px solid #dfdfdf;
    margin-bottom: 100px;
}

.swLeftWrapper {
    width: calc(50% - 2px);
    border-right: 1px solid #dfdfdf;
}

.swRightWrapper {
    width: 50%;
}

.swTitle {
    padding: 10px 10px;
    border-bottom: 1px solid #dfdfdf;
}

.signingSpace {
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.pocName {
    font-weight: 500;
}

.designation {
    padding: 10px 10px;
    border-top: 1px solid #dfdfdf;
}


.paymentDueDate {
    font-weight: 500;
}

.pocDesignation {
    font-size: 500;
    font-size: 8px;
}
.downloadPdfBtn{
    padding: 5px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
}
.signNowBtn{
    padding: 5px 10px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border: 1px solid #dfdfdf;
    background-color: #da1884;
    border: 1px solid #da1884;
    color: white;
}
.contactInfoWrapper{
    padding: 0px 10%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.eachContactInfo{
    display: flex;
    justify-content: space-between;
    border: 1px solid #dfdfdf;

}
.eciTitle{
    padding: 10px;
    width: 50%;
}
.eciValue{
    padding: 10px;
    font-weight: 500;
}


.storeName{
    font-size: 0.8rem;
    font-weight: 500;
}
.storeAddress{
    font-size: 0.5rem;
    font-weight: 500;
}



@media only screen and (max-width: 600px) {
    .mainBody {
        height: 80svh;
    }
    .mainWrapper{
        height: 95svh;
    }
    .mainFooter {
        height: calc(9svh - 1px);
    }
    .contactInfoWrapper{
        padding: 0px;
    }
}