.mainWrapperk{
    display: flex;
    justify-content: center;
    align-items: center;
}
button{
    cursor: pointer;
}
.mainContainerk{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.iconWrapper{
    padding: 30px;
}
.pleaseSignTxt{
    padding: 20px 5% 40px;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    color: #00000080;
}
.startSigningBtn button{
    padding: 12px ;
    width: 200px;
    background-color: #da1884;
    color: white;
    font-weight: 500;
    border: 1px solid #da1884;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    cursor: pointer;
}

.mainWrapper {
    position: relative;
    background-color: transparent;
    height: 80vh;
    width: 100%;
}

.mainContainer {
    position: relative;
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
}
.mainBodyWrapper{
    overflow: hidden;
    width: 100%;
}
.mainBodyWrapperSigned{
    overflow: hidden;
    width: 100%;
}
.titleWrapper{
    text-align: center;
    font-weight: 600;
    padding: 14px 0px;
}

.stepWrapper{
    position: relative;
    width: 100%;
    animation: slideRightToLeft .2s;
    -webkit-animation: slideRightToLeft .2s;
}
.firstNoteWrapper{
    text-align: justify;
    width: 90%;
    padding: 5%;
}
.firstNoteWrapper p{
    font-weight: 500;
    color: #474747;
}
.thinTxt{
    font-weight: 400;
    font-size: 0.8rem;
}

.otpSentWrapper{
    padding: 0px 5%;
    width: 90%;
}
.otpSentTitle{
    text-align: center;
    font-size: 0.7rem;
    font-weight: 500;
    color: #21212190;
}
.pleaseEnterOtp{
    text-align: center;
    color: #212121;
}


.authSignatoryTitle{
    text-align: center;
    padding: 0px 10%;
}

.textareaWrapper{
    width: 90%;
    padding: 10px 5%;
}
.textareaWrapper textarea{
    --textarea-height : 140px;
    width: calc(100% - 2px);
    border: 1px solid #dfdfdf;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--textarea-height);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #dfdfdf40;
}
.resendOtpWrapper{
    text-align: center;
    padding: 30px 0px 10px;
}
.resendOtpWrapper button{
    background-color: white;
    border: none;
    font-weight: 500;
}
.resendOtpTimerTxt{
    font-size: 0.7rem;
}


.actionWrapper{
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 5%;
    height: 10vh;
    gap: 10px;
}
.actionWrapperSigned{
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: center;
    padding: 0px 5%;
    height: 10vh;
    gap: 10px;

}
.actionWrapper button{
    width: 45%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.actionWrapperSigned button{
    width: 45%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.cancelOtp{
    border: 1px solid transparent;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    background-color: #e7e7e742;
    color: black;
    font-weight: 500;
    padding: 14px 2px;
}
.sendOtp{
    background-color: #da1884;
    color: white;
    font-weight: 500;
    border: 1px solid #da1884;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 14px 2px;
}


@keyframes slideRightToLeft {
    0% { left: 100%;}
    100% { left: 0%;}
}

@media only screen and (max-width: 600px) {
    
    .mainWrapper {
        height: 100vh;
        width: 100%;
        background-color: #00000080;
        position: fixed;
        top: 0px;
        left: 0px;
    }

    .mainContainer {
        position: relative;
        display: flex;
        width: 100%;
        height: 100vh;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .mainBodyWrapper {
        height: 58vh;
        width: 100%;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding: 0px 0px 8vh 0px;
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
    .mainBodyWrapperSigned{
        height: 58vh;
        width: 100%;
        background-color: white;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        padding: 0px 0px 8vh 0px;
        border-top-left-radius: 18px;
        border-top-right-radius: 18px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

    }
}