.mainContainerMobile {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    background-color: aliceblue;
    animation: leftToRight 0.4s forwards cubic-bezier(0, 1.11, 0.12, 0.98);
    -webkit-animation: leftToRight 0.4s forwards cubic-bezier(0, 1.11, 0.12, 0.98);
}
.mainContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100svh;
}


.eachSectionWrapper {
    width: 100%;
    padding: 0px 0px 20px 0px;
}

.sectionTitle {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
    padding: 12px 0px;
}

.eachOptionWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 0px;
}

.optionIcon {
    display: flex;
    align-items: center;
}

.optionTitle {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 500;
}

.sectionOptionsWrapper {
    padding: 0px 10px;
}

.mainBody{
    padding: 0px 5%;
    width: 90%;
    max-height: 84svh;
    overflow-y: scroll;
}
.mainFooter{
    padding: 0px 5% 20px;
    width: 90%;
    display: flex;
    justify-content: space-between;
}
.logoutBtn{
    padding: 20px 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    background-color: white;
    height: 50px;
    width: 50px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #a5a5a5;
}
.homeBtn{
    padding: 0px 80px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 36px;
    -webkit-border-radius: 36px;
    -moz-border-radius: 36px;
    -ms-border-radius: 36px;
    -o-border-radius: 36px;
    background-color: white;
    height: 50px;
    display: flex;
    align-items: center;

}



@keyframes leftToRight {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}