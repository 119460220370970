.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer{
    padding-top: 7vh;
    width: 100%;
}
.mainContent{
    width: 100%;
    display: flex;

}

.rightContentWrapper{
    width: 76%;
    padding: 20px 2% 20px 0%;
}
.settingOptionsWrapper{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding: 0px 5px;
}

.eachSettingWrapper{
    width: 30%;
    background-color: white;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    text-align: center;
    padding: 30px 0px;
}
.eswTxt{
    font-size: 0.9rem;
    padding: 20px 0px 0px;
    font-weight: 500;
}

@media only screen and (max-width: 600px) {
    .mainWrapper{
        height: 100svh;
    }
    .mainContainer{
        height: 93svh;
        padding-top: 7svh;
    }
    .mainContent{
        height: 93svh;
        padding: 0px;
    }
    .rightContentWrapper{
        height: 93svh;
        width: 96%;
        padding: 0px 2% 0px 2%;
    }
    .eachSettingWrapper{
        width: 100%;
    }
    .settingOptionsWrapper{
        height: 89svh;
        overflow-y: scroll;
        padding: 2svh 3%;
    }


}
