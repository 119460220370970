.mainContent {
    width: 30%;
    height: 50vh;
    background-color: white;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px; */

    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

    animation: bottomUp 0.08s forwards ease-in;
    -webkit-animation: bottomUp 0.08s forwards ease-in;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.mainWrapper {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
}

.mainContainer {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #000000ba;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes bottomUp {
    0% { margin-top: 50vh; }
    100% { margin-top: 0vh; }
}


.mainHeader{
    width: 100%;
    height: calc(7vh - 1px);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    background-color: #dfdfdf30;
    font-weight: 600;
}
.mainBody{
    width: 100%;
    height: 36vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.mainFooter{
    width: 100%;
    height: calc(7vh - 1px);
    border-top: 1px solid #dfdfdf;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eachLanguageWrapper{
    display: flex;
    height: 6vh;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    align-items: center;
}
.eachLangIcon{
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.eachLangTxt{
    font-weight: 500;
}

.eachLanguageWrapper:not(:last-child) {
    border-bottom: 1px solid #dfdfdf50; /* Apply border to all child divs except the last one */
}

.closeBtn{
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    background-color: transparent;
    color: #00000075;
    font-size: 0.8rem;
}

@media only screen and (max-width: 600px) {
    .mainContent{
        width: 90%;
        height: 60vh;
    }


    .mainHeader{
        width: 100%;
        height: 7vh;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .mainBody{
        width: 100%;
        height: 36vh;
    }
    .mainFooter{
        width: 100%;
        height: calc(7vh - 1px);
        border-top: 1px solid #dfdfdf;
    }

}

