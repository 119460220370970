.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer{
    padding-top: 7vh;
    width: 100%;
}
.mainContent{
    width: 100%;
    display: flex;

}

.rightContentWrapper{
    width: 76%;
    padding: 20px 2% 20px 0%;
}
.resumeTableWrapper{
    background-color: white;
    border-radius: 18px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    height: 86vh;
}

.filtersWrapper{
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 1.5%;
}
.leftFiltersWrapper{
    width: 60%;
}
.leftFiltersWrapper input{
    width: calc(100% - 14px);
    padding: 9px 8px;
    border: 1px solid #dfdfdf;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
}
.leftFiltersWrapper input:focus{
    outline: none;
}
.headerWrapper{
    height: 6vh;
    background-color: #F6F9FA;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 500;
}
.headerWrapperPhone{
    display: none;
}
.tableBodyWrapper{
    height: 72vh;
    overflow-y: scroll;
}
.eachRowWrapper{
    display: flex;
    background-color: #dfdfdf80;
    border-bottom: 2px solid white;
    padding: 10px;
}
.sn{
    width: 4%;
    text-align: center;
}
.candidateName{
    width: 20%;
}
.location{
    width: 60%;
}
.tableActionWrapper{
    display: flex;
    align-items: center;

}
.tableActionWrapper button{
    background-color: transparent;
    border: none;
    padding: 0px 10px;
}
.tableActionWrapper a {
    padding: 0px 15px 0px 5px;
}
.storeName{
    font-weight: 500;
}
.headerWrapper .rightAction{
    width: calc(20% - 10px);
    padding: 10px;
}
.rightAction{
    width: calc(20% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    padding: 0px;
}
.timeago{
    font-size: 0.6rem;
    font-weight: 500;
    color: #212121;
    text-align: right;
}
.viewDetails{
    display: flex;
    justify-content: flex-end;
    padding: 10px 0px 4px;
}
.viewDetails a{
    padding: 7px 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border: 1px solid #dfdfdf;
    background-color: #da1884;
    font-size: 0.8rem;
    font-weight: 500;
    color: white;
}
.cityName{
    font-weight: 500;
    font-size: 0.8rem;
}
.storeAddress{
    font-size: 0.6rem;
    font-weight: 500;
    color: #616161;
}
@media only screen and (max-width: 600px) {
    .mainWrapper{
        height: 100svh;
    }
    .mainContainer{
        height: 93svh;
        padding-top: 7svh;
    }
    .mainContent{
        height: 93svh;
    }
    .rightContentWrapper{
        width: 96%;
        padding: 1.5vh 2% 1.5vh 2%;
        height: 90svh;
    }
    .resumeTableWrapper{
        height: 90svh;
    }
    .filtersWrapper{
        height: 8svh;
    }
    .headerWrapper{
        flex-wrap: wrap;
        padding: 0px 2%;
        height: 8svh;
        display: none;
    }
    .headerWrapperPhone{
        padding: 0px 2%;
        height: 8svh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F6F9FA;
        font-size: 0.8rem;
        font-weight: 600;
    }
    .tableBodyWrapper{
        height: 74svh;
    }
    .eachRowWrapper{
        flex-direction: column;
        background-color: #f0f0f080;
        border-bottom: 2px solid white;
        padding: 10px;
    }
    .sn{
        width: 5%;
        text-align: center;
        display: none;
    }
    .storeAddress{
        font-size: 0.6rem;
        font-weight: 500;
        color: #616161;
    }
    .candidateName{
        width: 100%;
    }
    .location{
        width: 100%;
    }
    .rightAction{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    


}
