.mainWrapper {
    width: 100%;
    height: 7vh;

    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    position: fixed;
    top: 0px;
    left: 0px;
}

.mainContainer {
    position: relative;
    display: flex;
    width: 96%;
    height: 7vh;
    align-items: center;
    justify-content: space-between;
    padding: 0px 2%;
}
.brandNameWrapper{
    display: flex;
    flex-direction: column;
}
.brandName{
    font-weight: 500;
}
.tagWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.logout button{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 5px 10px;
}

.tag {
    padding: 2px 8px;
    font-size: 0.5rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #FCE9E1;
    font-weight: 600;
    border: 1.4px solid #da1884;
}
.tagPlus {
    padding: 2px 8px;
    font-size: 0.5rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #FCE9E1;
    font-weight: 600;
    border: 1.4px solid #da1884;
}

.tagEssential {
    padding: 2px 8px;
    font-size: 0.5rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #E5F4F2;
    font-weight: 600;
    border: 1.4px solid #da1884;
}

.tagBusiness {
    padding: 2px 8px;
    font-size: 0.5rem;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #E7EEF7;
    font-weight: 600;
    border: 1.4px solid #da1884;
    background-color: white;

}


.mainContainerMobile{
    display: none;
    justify-content: space-between;
    align-items: center;
    height: 7vh;
}
.messageWrapper{
    padding: 0px 2%;
}
.burgeMenu{
    padding: 0px 10px;
}



@media only screen and (max-width: 600px) {
    .mainWrapper{
        height: 7svh;
    }
    .mainContainer{
        display: none;
        height: 7svh;
    }
    .mainContainerMobile{
        display: flex;
        height: 7svh;
    }
    .brandName{
        font-weight: 500;
        font-size: 0.7rem;
        color: #202020;
    }
    .companyName{
        font-size: 0.8rem;
        font-weight: 500;
    }

}
