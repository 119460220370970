.rowWrapper{
    display: flex;
    align-items: center;
    padding: 8px 0px;
    font-weight: 500;
    font-size: 0.9rem;
}
.sn{
    width: 4%;
    text-align: center;
}
.candidateName{
    width: 16%;
}
.phoneNumber{
    width: 15%;
}
.profile{
    width: 20%;
}
.age{
    width: 16%;
}
.gender{
    width: 10%;
}
.action{
    width: 20%;
}

.action a{
    padding: 3px 8px;
    border: 1px solid #da1884;
    background-color: #da1884;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    font-weight: 500;
    font-size: 0.9rem;
    color: white;
}




@media only screen and (max-width: 600px) {

    .rowWrapper{
        flex-wrap: wrap;
        padding: 6px 2%;
        border-bottom: 1px solid #dfdfdf;
    }
    .sn{
        width: 5%;
        text-align: left;
    }
    .candidateName{
        width: 50%;
    }
    .phoneNumber{
        width: 35%;
    }
    .age{
        width: 10%;
    }
    .profile{
        width: 55%;
        padding: 0px 0px 0px 5%;
        color: #7c7c7c;
        font-size: 0.8rem;
    }
    .gender{
        width: 30%;
        color: #7c7c7c;
        font-size: 0.8rem;
    }
    .action{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    


}
