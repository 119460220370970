.mainWrapper {
    /* background-color: #FFAEBC; */
    /* background-color: #A0E7E5; */
    /* background-color: #e8e8e8; */
    background-color: #f7f7f7;
    /* background-color: #FBE7F2; */
    display: flex;
    flex: 1;
    height: 100svh;
    width: 100%;


    background-image: url(https://img.freepik.com/free-photo/vivid-blurred-colorful-background_58702-2655.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: color;
}

.mainContainer {
    padding-top: 7svh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.mainContent {
    width: 90%;
    height: 80svh;
    display: flex;
    flex-direction: column;
    background-color: white;
    animation: bottomUp 0.2s cubic-bezier(0.13, 0.74, 0, 0.97);
    -webkit-animation: bottomUp 0.2s cubic-bezier(0.13, 0.74, 0, 0.97);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
}

.searchWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10svh;
}
.searchBarWrapper{
    width: 100%;
}
.searchBarWrapper input{
    width: 100%;
}
.backBtn {
    padding: 0px 10px;
}

.searchWrapper input {
    width: 75%;
    padding: 12px 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: 1px solid #dfdfdf;
}

.profilesWrapper {
    height: 70svh;
    display: flex;
    gap: 30px;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: scroll;
}

.eachProfile {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 14px;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    -ms-border-radius: 14px;
    -o-border-radius: 14px;
    width: 200px;
    text-align: center;
    cursor: pointer;
    background-color: #c1c1c140;
}

.profileSelectedWrapper {
    padding: 0px 5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.twoInARowWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.groupedData {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
}

.selectedProfile {
    padding: 30px 10px;
    text-align: center;
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.quantWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.quantControlBtn {
    padding: 0px 20px;
}

.quant {
    font-size: 3rem;
}

.staffQuantWrapper {
    padding: 10px 10px;
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}

.quantQuestion {
    text-align: center;
    padding: 0px 20px 20px;
    font-weight: 600;
}

.timelineWrapper {
    padding: 30px 10px;
    text-align: center;
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    width: 45%;
}
.locationWrapper{
    padding: 30px 10px;
    width: 45%;
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
}
.locationTitle{
    font-size: 0.8rem;
    font-weight: 500;
}
.timelineQuestion {
    padding: 0px 0px 20px;
    font-weight: 600;
}

.timelineOptionsWrapper {
    display: flex;
    gap: 20px;
    padding: 0px 14px;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% - 28px);
}

.eachTimeline {
    padding: 14px 13px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-weight: 500;
    color: #202020;

    width: calc(47% - 28px);
    cursor: pointer;
}

.eachTimelineActive {
    padding: 14px 13px;
    border: 1px solid #da188445;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    font-weight: 500;
    color: #202020;

    width: calc(47% - 28px);
    background-color: #da188445;
    cursor: pointer;
}

.submitBtnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.submitBtn {
    padding: 14px 0px;
    box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    text-align: center;
    background-color: #da1884;
    color: white;
    font-weight: 500;
    width: 200px;
    border: 1px solid #da1884;
    cursor: pointer;
}
.selectedLocation{
    padding: 6px 0px;
}
.storeName{
    font-weight: 500;
    font-size: 0.9rem;
}
.storeAddress{
    font-size: 0.7rem;
}
.changeLocationBtn{
    padding: 4px 10px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-weight: 500;
    font-size: 0.8rem;
}


@keyframes bottomUp {
    0% {
        margin-top: 100%;
    }

    100% {
        margin-top: 0%;
    }
}



@media only screen and (max-width: 600px) {
    .mainWrapper {
        height: 100svh;
    }
    .mainHeaderWrapper{
        height: 7svh;
        border-bottom: 1px solid #dfdfdf;
        background-color: #dfdfdf40;
    }
    .mainBodyWrapper{
        height: 83svh;
        overflow-y: scroll;
        background-color: white;
    }
    .searchWrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 7svh;
    }
    .backBtnWrapper{
        width: 12%;
    }
    .searchBarWrapper{
        width: 88%;
    }
    .searchBarWrapper input{
        width: 80%;
    }
    .backBtn {
        padding: 0px 10px;
    }
    
    .searchWrapper input {
        width: calc(100% - 22px);
        padding: 12px 10px;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        border: none;
        background-color: transparent;
    }
    .searchWrapper input:focus{
        outline: none;
    }

    .mainContent {
        width: 100%;
        position: fixed;
        top: 0px;
        height: 100svh;
        border-radius: 0px;
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        -ms-border-radius: 0px;
        -o-border-radius: 0px;
    }

    .twoInARowWrapper {
        width: 100%;
    }

    .profileSelectedWrapper {
        flex-direction: column;
        padding: 20px 5%;
    }


    .profilesWrapper {
        padding: 15px 0px;
        height: calc(90svh - 32px);
        overflow-y: scroll;
        gap: 10px;
    }
    .eachProfile{
        padding: 14px;
        width: 280px;
        font-size: 0.8rem;
        font-weight: 500;
    }
    .groupedData {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .selectedProfile {
        padding: 20px 10px;
        text-align: center;
        box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
    }
    .quantQuestion{
        font-size: 0.8rem;
    }
    .timelineWrapper {
        width: calc(100% - 20px);
        height: 35svh;
        padding: 20px 10px 30px;
    }
    .locationWrapper{
        padding: 30px 10px;
        width: 94%;
        box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
        border-radius: 14px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
}
    .timelineOptionsWrapper {
        gap: 10px;
    }

    .timelineQuestion {
        padding: 0px 0px 12px;
    }

    .eachTimeline {
        width: 100%;
        font-size: 0.8rem;
    }

    .eachTimelineActive {
        width: 100%;
        background-color: #da188445;
        border: 1px solid #da188445;
        font-size: 0.8rem;
    }

    .submitBtnWrapper {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 8vh;
        border-top: 1px solid #dfdfdf;
    }

    .submitBtnContainer {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        padding: 0px 5%;
    }

    .submitBtn {
        width: 100%;
    }

}